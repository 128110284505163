import React from 'react';
import {
  Navbar,
  NavbarBrand,
  Button,
  Container
} from 'reactstrap';
import './Topbar.css';  // Import CSS for better styling

function Topbar() {
  return (
    <Navbar color="light" className="my-0 shadow-sm p-3 bg-white rounded">
      <Container className="d-flex justify-content-between align-items-center">
        <NavbarBrand href="/">
          <strong>VideoTranscoding</strong>
        </NavbarBrand>
        <div className="d-flex">
          <div className="me-2">
            <Button color="primary" href="/login">
              Login
            </Button>
          </div>
          <div className="me-2">
            <Button color="success" href="/signup">
              Sign Up
            </Button>
          </div>
        </div>
      </Container>
    </Navbar>
  );
}

export default Topbar;
