//upload without parameter store
// Upload.js
import React, { useState } from 'react';
import axios from 'axios';
import { Container, Form, Button, Alert, Spinner } from 'react-bootstrap';
import TopbarHome from './TopbarHome';

const UploadComponent = ({ token }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [message, setMessage] = useState('');

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setMessage('');
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      setMessage('Please select a file to upload.');
      return;
    }

    setUploading(true);

    try {
      // Request a pre-signed URL for upload
      const { data } = await axios.post(`${process.env.REACT_APP_API_URL}/generate-upload-url`, {
        fileName: selectedFile.name,
      }, {
        headers: { Authorization: `Bearer ${token}` },  // Pass ID Token
      });

      const { uploadUrl, videoId } = data;

      // Upload file to S3 using the pre-signed URL
      await axios.put(uploadUrl, selectedFile, {
        headers: { 'Content-Type': selectedFile.type },
      });

      // Save metadata in DynamoDB after successful upload
      await axios.post(`${process.env.REACT_APP_API_URL}/upload-complete`, {
        videoId,
        fileName: selectedFile.name,
      }, {
        headers: { Authorization: `Bearer ${token}` },  // Pass ID Token
      });

      setMessage('File uploaded and metadata saved successfully!');
      setSelectedFile(null);
    } catch (error) {
      console.error('Error uploading file:', error);
      setMessage('Failed to upload file.');
    } finally {
      setUploading(false);
    }
  };

  return (
    <div>
      <TopbarHome />
      <Container className="mt-4">
        <h2 className="text-center">Upload a Video</h2>
        {message && <Alert variant={message.includes('successfully') ? 'success' : 'danger'}>{message}</Alert>}
        <Form>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Select Video File</Form.Label>
            <Form.Control type="file" accept="video/*" onChange={handleFileChange} />
          </Form.Group>
          <div className="text-center">
            <Button variant="primary" onClick={handleUpload} disabled={uploading}>
              {uploading ? <Spinner animation="border" size="sm" /> : 'Upload'}
            </Button>
          </div>
        </Form>
      </Container>
    </div>
  );
};

export default UploadComponent;
