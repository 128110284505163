import React from "react";
import Topbar from '../components/Topbar';
import './Welcome.css';  // Import CSS for better styling

function Welcome() {
  return (
    <div>
      <Topbar />
      <div className="welcome-container">
        <div className="welcome-text">
          <h1>Welcome to VideoTranscoding</h1>
          <div className="welcome-buttons">
            <a href="/login" className="btn btn-primary">Login</a>
            <a href="/signup" className="btn btn-success">Sign Up</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Welcome;
