// components/Topbar-Home.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  Container
} from 'reactstrap';

const Topbar_Home = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token'); // Directly remove token from local storage
    navigate('/login'); // Redirect to login page
  };

  return (
    <Navbar color="light" light expand="md" className="my-0">
      <Container className="d-flex justify-content-between align-items-center">
        <NavbarBrand href="/">VideoTranscoding</NavbarBrand>
        <Nav className="d-flex flex-row" navbar>
          <NavItem>
            <NavLink href="/files/">Files</NavLink>
          </NavItem>
          <NavItem className="ms-3">
            <NavLink href="/upload/">Upload</NavLink>
          </NavItem>
        </Nav>
        {/* Adding Logout button directly */}
        <Button color="danger" onClick={handleLogout}>Logout</Button>
      </Container>
    </Navbar>
  );
};

export default Topbar_Home;