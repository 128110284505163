// App.js
import React, { useState, useEffect } from "react";
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Welcome from "./pages/Welcome";
import Login from "./pages/Login";
import Upload from "./components/Upload";
import FileList from "./components/FileList";
import Signup from "./pages/Signup";

function App() {
  const [token, setToken] = useState("");

  // Function to set the token and store it in localStorage
  const handleSetToken = (newToken) => {
    console.log("Token received:", newToken); // For debugging purposes
    setToken(newToken);
    localStorage.setItem('token', newToken); // Store token in localStorage
  };

  // Optionally, check for token on app start and set it in state
  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      setToken(storedToken);
    }
  }, []);

  return (
    <Router>
      {/*<Topbar />*/}
      <div>
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="/signup" element={<Signup />} />
          {/* Pass handleSetToken to Login component */}
          <Route path="/login" element={<Login setToken={handleSetToken} />} />
          {/* Protect the upload route by checking if the token exists */}
          {token && (
            <>
              <Route path="/upload" element={<Upload token={token} />} />
              <Route path="/files" element={<FileList token={token} />} /> {/* Route for FileList */}
            </>
          )}
        </Routes>
      </div>
      {/*<Footer/>*/}
    </Router>
  );
}

export default App;