import React from 'react';
import {
  Navbar,
  NavbarBrand,
  Container
} from 'reactstrap';

function Topbar() {
  return (
    <Navbar color="light" className="my-0">
      <Container className="d-flex justify-content-between align-items-center">
        <NavbarBrand href="/">
          VideoTranscoding
        </NavbarBrand>
      </Container>
    </Navbar>
  );
}

export default Topbar;